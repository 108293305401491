import { Image } from '/features/buildingBlocks/Image'

import styles from './FullWidthImage.css'

export function FullWidthImage({ image }) {
  return (
    <div className={styles.component}>
      {image?.asset?._ref && <Image {...{ image }} />}
    </div>
  )
}
